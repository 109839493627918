const accueilcarousel = new Swiper('#carousel-accueil', {
  slidesPerView: 1,
  speed: 500,
  effect: 'fade',
  autoplay: {
    delay: 5000,
  },
});

accueilcarousel.on('slideChange', () => {
  jQuery('#carousel-accueil-currentslide').text(accueilcarousel.realIndex + 1);
  jQuery('#carousel-accueil-currentslide')
    .addClass('animation')
    .delay(500)
    .queue(() => {
      jQuery(this).removeClass('animation').dequeue();
    });
});

jQuery('#carousel-accueil-maxslide').text(
  '0' +
    document.querySelectorAll('.accueil__grid__swiper__wrapper__slide').length +
    ''
);
